import React from "react";
import NavigationBar from "../components/NavigationBar";
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image";
import NewsletterSignUp from "../components/NewsletterSignUp";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import {siteMetadata} from "../../gatsby-config";

const ComingSoon = () => {
    const imageData = useStaticQuery(graphql`
    {
        constructionImage: file(
        extension: {eq: "jpeg"},
        name: {eq: "construction"}) {
        childImageSharp {
          fluid {
            src
            srcSet
            sizes
            srcSetWebp
          }
        }
      }
    }
    `)

    return (
        <div className="w-full h-screen">
            <Layout>
                <Seo title="FlatPack FinTech - Coming Soon"/>
                    <NavigationBar />
            </Layout>
            <Img className="absolute top-0 left-0 object-contain w-screen h-full max-h-[30rem] opacity-75" fluid={imageData.constructionImage.childImageSharp.fluid} alt="Construction worker building the new content you wanted" />
            <h1 className="text-center text-6xl font-bold text-yellow drop-shadow-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">Coming Soon</h1>
            <NewsletterSignUp headline="Be the first to hear" tagline="We'll email you as soon as this has been launched"/>
            <Footer/>
        </div>

    )
}

export default ComingSoon;