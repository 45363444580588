module.exports = {
    siteMetadata: {
        title: `FlatPack FinTech`,
        author: {
            name: `Simon Dunkelman`,
            summary: `who lives and works in London, building the next generation of Web3 products and startups.`,
        },
        description: 'Build your next product or startup with our easy DIY guides and courses.',
        social: {
            twitter: 'flatpackfintech',
        },
        siteUrl: `https://www.flatpackfintech.com`,
    },
    plugins: [
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    'gatsby-plugin-postcss',
    `gatsby-plugin-fontawesome-css`,
    `gatsby-plugin-image`,
    `gatsby-transformer-json`,
    'gatsby-plugin-netlify',
    {
        resolve: `gatsby-source-filesystem`,
        options: {
            name: `pages`,
            path: `${__dirname}/src/posts/`,
        },
    },
    {
        resolve: `gatsby-source-filesystem`,
        options: {
            path: `${__dirname}/src/content/`,
        },
    },
    {
        resolve: `gatsby-source-filesystem`,
        options: {
            name: `images`,
            path: `${__dirname}/src/images`,
        },
    },
    {
        resolve: `gatsby-plugin-manifest`,
        options: {
            short_name: `FlatPack FinTech`,
            start_url: `/`,
            background_color: `#ffffff`,
            theme_color: `#ffffff`,
            icon: `src/images/favicon.png`, // This path is relative to the root of the site.
        },
    },
    `gatsby-remark-images`,
    {
        resolve: `gatsby-plugin-mdx`,
        options: {
            extensions: [`.mdx`, `.md`],
            defaultLayout: require.resolve('./src/templates/BlogPost.js'),
            gatsbyRemarkPlugins: [
                {
                    resolve: `gatsby-remark-images`,
                    options: {
                        maxWidth: 1200,
                    },
                },
            ],
        },
    },
    ]
}